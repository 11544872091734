@import "../styles/colors";
@import "../styles/fonts";

button {
  border: none;
  outline: none;

  font-family: $fontDefault;
  font-style: normal;
  font-weight: 600;
  font-size: 16px;
  line-height: 26px;

  background: $blue;
  padding: 15px 20px;
  gap: 10px;
  color: $white;
  width: fit-content;
  display: flex;
  justify-content: center;

  border-radius: 2px;
  margin-bottom: 22px;

  cursor: pointer;

  &:hover{
    background: $blue2;
  }

  &.trial,
  &.admin,
  &.buy {
    width: 100%;
    max-width: 240px;
  }

  &.admin,
  &.trial {
    border: 1px solid $gray3;
    color: $black;
  }

  &.admin{
    background-color: $white;
  }

  &.trial{
    position: relative;
    white-space: nowrap;
    background-color: $gray2;

    &:after {
      content: "";
      height: 20px;
      width: 20px;

      margin-left: 4px;

      display: inline-block;

      background-position: center;
      background-image: url(data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMjAiIGhlaWdodD0iMjAiIHZpZXdCb3g9IjAgMCAyMCAyMCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4KPHBhdGggZD0iTTcuNzMyNzEgNC4yMDY5NEM4LjAzMjYzIDMuOTIxMjUgOC41MDczNyAzLjkzMjc5IDguNzkzMDYgNC4yMzI3MUwxMy43OTQ0IDkuNDgzMThDMTQuMDcwMyA5Ljc3Mjg1IDE0LjA3MDMgMTAuMjI4MSAxMy43OTQ0IDEwLjUxNzhMOC43OTMwNiAxNS43NjgyQzguNTA3MzcgMTYuMDY4MSA4LjAzMjYzIDE2LjA3OTcgNy43MzI3MSAxNS43OTRDNy40MzI3OSAxNS41MDgzIDcuNDIxMjUgMTUuMDMzNiA3LjcwNjk0IDE0LjczMzZMMTIuMjE1NSAxMC4wMDA1TDcuNzA2OTQgNS4yNjcyOUM3LjQyMTI1IDQuOTY3MzcgNy40MzI3OSA0LjQ5MjY0IDcuNzMyNzEgNC4yMDY5NFoiIGZpbGw9ImJsYWNrIi8+Cjwvc3ZnPgo=);
    }
  }
}
