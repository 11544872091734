@import "../../styles/fonts";
@import "../../styles/colors.scss";

.style-editor {
  width: 100vw;
  height: 100vh;

  display: flex;
  text-align: center;
  align-items: center;
  align-content: center;

  font-family: $fontDefault;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 28px;

  flex-direction: column;
  justify-content: space-around;

  label {
    margin-top: 30px;
    margin-bottom: 15px;
    font-weight: bold;
  }

  select {
    background-color: $white;
    border: 1px solid $gray3;
    border-radius: 2px;
    padding: 15px;
    width: 200px;
    font-size: 15px;
    font-weight: normal;
  }

  input {
    padding: 15px;
    width: 200px;
    font-size: 15px;
    margin: 10px;
  }

  input[type=checkbox] {
    width: 25px;
    height: 25px;
  }

  .delete-button {
    background-color: $red;
  }

  .flex-row {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: flex-start;
  }

}

.style-editor form {
  text-align: left;
  align-items: left;
  align-content: left;

}

.style-editor * {
  display: block;
}

.style-editor-controls {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  margin-top: 20px;
  
  * {
    margin-right: 20px;
  }
}

.style-editor-settings {
  display: block;
  margin-top: 20px;
}

.response-message {
  margin-left: 20px;

  opacity: 0;
  transition: opacity 1.5s ease;
}