$white: #FFFFFF;
$black: #000000;
$blue: #0078D4;
$blue2: #005A9E;
$red: #D83B01;

$gray: #323130;
$gray2: #F3F2F1;
$gray3: #8A8886;

