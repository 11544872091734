@import "../../styles/fonts";

.activator {
  width: 100vw;
  height: 100vh;

  display: flex;
  text-align: center;
  align-items: center;
  align-content: center;

  font-family: $fontDefault;
  font-style: normal;
  font-weight: 400;
  font-size: 20px;
  line-height: 28px;

  flex-direction: column;
  justify-content: space-around;
}

